import * as React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

export const PrivacyDisclaimerPage = (): React.ReactElement => {
	return (
		<Layout>
			<SEO title="CommerSight | Aviso de Privacidad" />

			<section className="back-white" id="aviso">
				<div className="container-aviso" id="terminos">
					<div className="title-section">
						<h2>Términos y políticas</h2>
					</div>
					<p className="sub-black">Políticas legales y de uso del Servicios</p>
					<p className="black-txt">
						El presente documento es un contrato vinculante entre usted y nosotros. Por el sólo hecho de
						usar nuestros servicios disponibles en el sitio web alojado en el dominio
						https://commersight.com por registrarse en el mismo, o de cualquier otro modo utilizar dichos
						servicios, usted expresa su consentimiento incondicional a los mismos. Usted reconoce ser mayor
						de edad y en caso de que pretenda utilizar nuestros servicios para una persona moral, nos
						manifiesta y garantiza en este acto que tiene facultades para tal efecto y que la persona moral
						que usted representa queda en este acto vinculada por el presente documento, siendo usted
						solidariamente e ilimitadamente responsable con la misma ante nosotros por cualquier
						incumplimiento al presente documento.
					</p>
					<p className="sub-black">Asignación de Responsabilidad</p>
					<p className="black-txt">
						Usted comprende, reconoce y acuerda que usted es el único responsable de utilizar los servicios
						descritos en el párrafo anterior y, específicamente, que usted es el único responsable de la
						veracidad, autenticidad y precisión de cualquier información que ingrese al momento de
						registrarse y al momento de usar nuestros servicios de cualquier forma. Más aún, usted sabe y
						reconoce que crear comprobantes fiscales con datos falsos o inexactos es una conducta sancionada
						por las leyes mexicanas. Por ello, usted acuerda sacarnos en paz y a salvo y cubrir íntegramente
						cualquier multa, daños, perjuicios, gastos, costas, honorarios de abogados y cualquier otra
						cantidad que nosotros debamos cubrir o erogar de cualquier forma, con motivo de cualquier tipo
						de queja, denuncia, demanda, querella, procedimiento administrativo, procedimiento judicial,
						requerimiento, citatorio, procedimiento arbitral y cualquier otro procedimiento o acto legal que
						se funde o pretenda fundarse en que usted ingresó información falsa o incorrecta en nuestros
						servicios, simuló operaciones o, en general, creo comprobantes fiscales por operaciones que no
						existen, no tuvieron lugar o no se reflejan adecuadamente en el comprobante fiscal respectivo.
						Nos reservamos en todo momento el derecho de interrumpirle el servicio sin derecho alguno a
						indemnización o devolución a usted, en caso de que a nuestro juicio usted utilice o pretenda
						utilizar nuestros servicios para simular operaciones o en caso de que su nombre o el de la
						persona moral que represente sean publicados en el Diario Oficial de la Federación en términos
						de los artículos 69 b y 69 b bis del Código Fiscal de la Federación y no se logre desvirtuar los
						hechos a que tales preceptos refieren.
					</p>
					<p className="sub-black">Limitación de responsabilidad</p>
					<p className="black-txt">
						Del mismo modo usted reconoce ser el único responsable del cumplimiento de sus obligaciones
						fiscales y, en caso de que utilice nuestros servicios para una persona moral o algún otro
						tercero, acuerda sacarnos en paz y a salvo y cubrir íntegramente cualquier multa, daños,
						perjuicios, gastos, costas, honorarios de abogados y cualquier otra cantidad que nosotros
						debamos cubrir o erogar de cualquier forma, con motivo de cualquier tipo de queja, denuncia,
						demanda, querella, procedimiento administrativo, procedimiento judicial, requerimiento,
						citatorio, procedimiento arbitral y cualquier otro procedimiento o acto legal con motivo de
						cualquier acto u omisión que tales terceros nos imputen. Nosotros no somos bajo ningún concepto
						responsables de entregar comprobantes fiscales a sus clientes, proveedores, patrones ni ningún
						otro tercero. Es su exclusiva responsabilidad entregar los mismos a cualquier tercero en
						términos de la legislación fiscal y nos libera de cualquier responsabilidad al respecto.
					</p>
					<p className="sub-black">Interpretación y controversias</p>
					<p className="black-txt">
						Para cualquier controversia que tenga con nosotros, usted acuerda sujetarse a las leyes y
						procedimientos judiciales locales de la Ciudad de México y usted renuncia expresamente a
						cualquier otra jurisdicción que pueda corresponderle, con motivo de su domicilio, su
						nacionalidad, o cualquier otra razón. Ninguna otra corte, tribunal o juzgado podrá tener
						jurisdicción sobre nosotros con motivo de nuestras obligaciones hacia usted o sus derechos ante
						nosotros y usted así lo acepta incondicionalmente. Para efectos de interpretar el presente
						documento y los derechos y obligaciones que nosotros tenemos ante usted y usted ante nosotros,
						usted acuerda someterse en primer lugar a las disposiciones del presente documento. Ante la
						ausencia u oscuridad del mismo, usted acuerda que el Código de Comercio vigente en México al
						momento de aceptar el presente documento proveerá lo conducente, siendo supletorios en primer
						lugar el Código Civil Federal vigente en México al momento de aceptar este documento y en
						segundo lugar el Código Civil vigente en la Ciudad de México al momento de aceptar este
						documento. El presente contrato asimismo deberá ser entendido en el significado ordinario que
						tengan sus palabras en léxico y costumbres de la Ciudad de México, sin importar el lugar donde
						usted resida, su idioma natal, su nacionalidad o el país de donde sea originario. El presente
						contrato sustituye, sobresee y deja sin efectos cualquier acuerdo previo entre usted y nosotros
						en sentido contrario. Ninguna comunicación previa que haya tenido lugar entre usted y nosotros
						puede prevalecer en contra del presente documento o modificar el mismo.
					</p>
					<p className="sub-black">Modificaciones</p>
					<p className="black-txt">
						Usted no puede modificar el presente documento sino mediante escrito que contenga nuestra firma
						autógrafa. Cualquier modificación realizada verbalmente o por algún otro medio que no sea el
						antes descrito no surtirá efecto alguno entre las partes. Nos reservamos el derecho de cambiar
						la presente política en cualquier momento, por el sólo hecho de continuar utilizando nuestros
						servicios usted acepta incondicionalmente cualquier cambio a los mismos que podamos realizar a
						los mismos. Es su exclusiva responsabilidad verificar este documento de momento en momento.
					</p>
					<p className="sub-black">Propiedad intelectual</p>
					<p className="black-txt">
						El contenido del sitio correspondiente al dominio comersight.com, así como nuestros servicios y
						todo código, interfaz, programa o base de datos colocado en el mismo para su utilización están
						sujetos a derechos de autor. Usted puede utilizar los mismos sólo en la exclusiva medida que sea
						necesaria para poder utilizar nuestros servicios, acorde con el presente documento. Cualquier
						otro uso incluyendo sin limitar, ingeniería inversa, la creación de obras derivadas,
						comunicación pública, reproducción más allá de una copia efímera en memoria RAM u otra memoria
						necesaria para usar nuestros servicios, están estrictamente prohibidos. Le otorgamos sólo una
						licencia no exclusiva, limitada al territorio de México para utilizar nuestros servicios,
						mientras continúe utilizando nuestros servicios acorde con el presente documento. Nada de lo
						dispuesto en el presente documento es o puede interpretarse como una cesión de derechos de
						propiedad intelectual de nosotros hacia usted. “Propiedad Intelectual” comprende para efectos de
						este documento cualquiera de los derechos sobre distintivos, invenciones, obras, personajes,
						interpretaciones, ejecuciones y cualquier otro derecho de los señalados por el artículo 2 del
						Convenio que establece la Organización Mundial de la Propiedad Intelectual. Todo intangible
						concerniente a nuestros servicios es de nuestra propiedad y usted acuerda no utilizar ni
						registrar o patentar intangibles idénticos o similares, ni en México ni en el extranjero y
						cedernos por escrito y a su costo cualquier registro o patente que tramite en contravención a
						este párrafo a favor de quien nosotros le indiquemos. Usted acuerda que cualquier obra derivada
						que cree de nuestras obras en contravención a este precepto o cualquier otro contenido sobre el
						cual tengamos derechos de propiedad intelectual es una obra por encargo nuestra en términos del
						artículo 83 de la Ley Federal del Derecho de Autor vigente en México y no puede usarla sin
						nuestro consentimiento.
					</p>
					<p className="sub-black">Ausencia de relación laboral</p>
					<p className="black-txt">
						Usted y nosotros somos partes contratantes independientes. Usted no es ni ha sido nuestro
						empleado o trabajador y nosotros no somos su patrón o patrón beneficiario ni tenemos ningún tipo
						de obligación patronal o de seguridad social a su favor por el uso de los servicios, y usted nos
						exime de toda responsabilidad al respecto y acuerda sacarnos en paz y a salvo de cualquier
						reclamo en sentido contrario.
					</p>
					<p className="sub-black">Costos</p>
					<p className="black-txt">
						Nuestros servicios no son gratuitos, tienen los costos que se muestran en https://comersight.com
						Si no recibimos su pago por cualquier motivo, como puede ser ejemplificativamente por así
						determinarlo su banco o proveedor de tarjeta de crédito, le retiraremos el servicio sin
						responsabilidad alguna para nosotros a nuestra entera discreción y sin necesidad de darle algún
						tipo de aviso o notificación. No hay plazo de gracia alguno para cumplir con sus obligaciones
						hacia nosotros. Usted podrá utilizar los servicios sólo para emitir el número de comprobantes
						que haya pagado conforme al precio que se muestra en nuestro sitio. Nos reservamos en todo
						momento el derecho de cambiar nuestros precios futuros sin previo aviso y es su responsabilidad
						consultar nuestro sitio para saber el precio que tiene el servicio que desea contratar.
					</p>
					<p className="sub-black">Pagos con tarjeta débito / crédito</p>
					<p className="black-txt">
						Los cargos realizados a su tarjeta de crédito o débito como pago de nuestro servicio se verán
						reflejados como un crédito a favor en su cuenta en un plazo menor a 72 horas, recibirá una
						notificación por correo electrónico confirmando su pago. Usted es y será completamente
						responsable del monto a cobrar y nosotros no tendremos responsabilidad alguna sobre el monto
						seleccionado una vez solicitado el cargo a su medio de pago. Descontaremos cualquier costo,
						impuesto o tarifa de nuestro proveedor de pagos, notificando explícitamente el monto total a
						cargar a favor de su cuenta y usted acepta plenamente dicho descuento.
					</p>
					<p className="sub-black">Pagos domiciliados</p>
					<p className="black-txt">
						En caso de que así lo determinemos a nuestra total y absoluta discreción y sin responsabilidad
						alguna hacia usted, podremos permitirle durante el tiempo que lo estimemos conveniente pagar
						nuestros servicios mediante cargos recurrentes a su tarjeta de crédito o débito. No asumimos
						responsabilidad alguna por las acciones u omisiones de su institución bancaria o el proveedor de
						su tarjeta de crédito y si no recibimos el pago por nuestros servicios y usted acuerda que es
						solidariamente e ilimitadamente responsable por pagar nuestros servicios en caso de que no los
						pague su banco o proveedor de tarjeta de crédito.
					</p>
					<p className="sub-black">Devoluciones</p>
					<p className="black-txt">
						En caso de que por cualquier motivo decida dejar de utilizar nuestros servicios quedando
						determinado número de comprobantes que haya pagado sin utilizar, usted acuerda que podrá
						solicitar la devolución por el monto que corresponda a aquellos que no haya utilizado,{' '}
						<strong>
							siempre y cuando no los haya pagado hace más de treinta días naturales y por un monto máximo
							de hasta $5,000 pesos de los Estados Unidos Mexicanos.
						</strong>{' '}
						Descontaremos cualquier costo, impuesto o tarifa bancaria que nos veamos obligados a realizar
						para hacer la devolución y usted acepta plenamente dicho descuento.
					</p>
					<p className="sub-black">Privacidad</p>
					<p className="black-txt">
						Usted reconoce que ha leído nuestro aviso de privacidad disponible en el sitio
						https://www.commersight.com/aviso-privacidad.html y nos expresa su total conformidad con el
						mismo Si con motivo de cualquier requerimiento, notificación judicial, citatorio o cualquier
						otro acto de autoridad nos vemos obligados a compartir la información sobre como utiliza
						nuestros servicios, usted nos otorga en este acto toda autorización necesaria para tal efecto.
					</p>
					<p className="sub-black">Responsabilidad por cuenta y contraseña</p>
					<p className="black-txt">
						Usted es el único responsable por custodiar y guardar en forma segura el nombre de usuario y la
						contraseña que utilice para acceder a nuestros servicios. Si usted decide compartirlos con
						terceros, en este acto manifiesta su total conformidad con que cualquier contratación u
						operación se entienda realizada por usted, manifestándonos también que tales terceros lo
						representan y cuentan con todas las facultades necesarias para obligarlo contractualmente y
						específicamente que pagará usted íntegramente cualquier servicio contratado por terceros a los
						que haya proporcionado su usuario y contraseña.
					</p>
					<p className="sub-black">Notificaciones</p>
					<p className="black-txt">
						Para cualquier comunicación concerniente a su uso de nuestros servicios y para cualquier
						notificación judicial o extrajudicial a efecto que podamos exigirle el cumplimiento de sus
						obligaciones conforme al presente documento, usted acuerda que le sea entregada cualquier
						notificación o documento por medio de la dirección física y correo electrónico que señaló al
						momento de registrarse para nuestros servicios. Para cualquier comunicación o notificación que
						desee enviarnos señalamos el domicilio ubicado en Perif. Blvd. Manuel Ávila Camacho 191,
						Polanco, Militar, Miguel Hidalgo, 11570 Ciudad de México, México, y el correo electrónico
						info@commersight.com
					</p>
					<p className="sub-black">PACTA SUNT SERVANDA</p>
					<p className="black-txt">
						Usted manifiesta que tiene todos los conocimientos, capacidad y recursos económicos para poder
						aceptar este contrato y cumplir plenamente con sus términos, por lo que acuerda dar pleno
						cumplimiento al mismo, inclusive ante situaciones extraordinarias e imprevisibles. El presente
						contrato carece de cualquier tipo de clausula rebus sic stantibus o cualquier otra clausula
						implícita o expresa que permita modificar a su favor sus condiciones por situaciones
						extraordinarias o imprevisibles tales como guerras, desórdenes civiles, desastres naturales,
						devaluaciones y otras circunstancias económicas, desastres naturales, huelgas, restricciones a
						importaciones o exportaciones, enfermedades, entre otras.
					</p>
					<p className="sub-black">Vigencia y terminación</p>
					<p className="black-txt">
						Las disposiciones del presente documento se entenderán en vigor a partir de que nos proporcione
						información para usar nuestros servicios o use los mismos en cualquier modo y se mantendrá en
						vigor hasta en tanto continúe usando nuestros servicios, con excepción de las disposiciones en
						materia de propiedad intelectual, las cuales continuarán en vigor terminado el presente
						contrato, por quince años después de terminada la vigencia.
					</p>
					<p className="black-txt">
						Usted puede dar por terminado sus obligaciones bajo el presente contrato, excepto aquellas
						referentes a temas de propiedad intelectual, dejando de utilizar nuestros servicios y eliminando
						su cuenta en los mismos. Ello no le eximirá de cumplir las obligaciones de pago que hubiere
						adquirido ni de las responsabilidades civiles en que hubiese incurrido por incumplimiento al
						presente documento. Nosotros podemos dejar de prestarle nuestros servicios y dar por terminado
						el contrato, sin necesidad de darle notificación judicial o extrajudicial alguna, ni plazo de
						gracia alguno, sin perjuicio de exigir el cumplimiento del presente contrato y la indemnización
						que pueda correspondernos en los casos siguientes:
					</p>
					<ol>
						<li>Que incumpla con el presente contrato.</li>
						<li>
							Que utilice nuestros servicios para simular operaciones o para cualquier otro propósito
							distinto a obtener comprobantes fiscales por operaciones legítimas.
						</li>
						<li>
							Que las autoridades fiscales determinen que usted simula operaciones y así lo publiquen en
							el Diario Oficial de la Federación.
						</li>
						<li>
							Que el ministerio público ejercite acción penal en contra de usted por delitos contra la
							salud, delitos en materia fiscal, financiamiento de terrorismo, operaciones con recursos de
							procedencia ilícita, fraude, peculado o falsificación de documentos.
						</li>
						<li>
							Que se determine por resolución administrativa o jurisdiccional firme que usted ha
							incumplido cualquier contrato administrativo, contrato de obra pública o cualquier contrato
							que haya celebrado con entidades de la administración pública federal, estatal, municipal,
							ya sea centralizada o paraestatal, organismos constitucionales autónomos o cualquier otra
							autoridad.
						</li>
						<li>
							Que se determine por resolución administrativa o jurisdiccional firme que usted ha hecho una
							ilegal utilización de recursos públicos.
						</li>
						<li>
							Que usted se vea involucrado con autoridades, partidos políticos o sindicatos de tal forma
							que se le acuse mediante denuncia o querella ante el ministerio público de pagos indebidos,
							actos de corrupción, regalos o cualquier otro acto a efecto de persuadir a una autoridad
							para indebidamente eximirle de alguna obligación o sanción o de actuar indebidamente de
							determinada forma en su favor.
						</li>
						<li>
							Que usted señale información falsa o inexacta al momento de crear su cuenta para usar
							nuestros servicios o que de alguna otra forma entorpezca o impida hacer valer nuestros
							derechos bajo el presente contrato.
						</li>
						<li>
							Que usted pague nuestros servicios con tarjetas o cuentas bancarias o de crédito falsas,
							clonadas, robadas o que de alguna otra forma no tenga derecho a utilizar.
						</li>
						<li>
							Que utilice nuestros servicios en la realización o consecución de cualquier actividad
							sancionada por las leyes penales o administrativas de México o para cometer cualquier
							ilícito, ya sea en nuestro perjuicio o el de terceros.
						</li>
						<li>
							Si en cualquier momento usted es parte demandada en procedimiento concursal, si usted
							fallece, la persona moral que representa es disuelta, fusionada o escindida o alguna otra
							circunstancia extraordinaria y ajena a nosotros puede poner, en nuestra opinión, en riesgo
							su capacidad de cumplir con el presente contrato.
						</li>
					</ol>
				</div>
				<div className="container-aviso" id="privacidad">
					<div className="title-section">
						<h2>Acuerdo de privacidad y Servicios</h2>
					</div>
					<p className="black-txt">
						TECNOLOGÍA EN GESTIÓN DE COMERCIO ELECTRÓNICO, S.A.P.I. DE C.V., con domicilio en Perif. Blvd.
						Manuel Ávila Camacho 191, Polanco, Militar, Miguel Hidalgo, 11570 Ciudad de México, México, es
						el responsable de la protección de los datos personales que usted proporcione al registrarse en
						el sitio correspondiente al dominio fiscalpop.com, y al respecto le informa lo siguiente:
					</p>
					<ol>
						<li>
							Los datos personales que recabamos de usted, los utilizaremos para para poderle prestar
							nuestros servicios y que usted pueda utilizarlos para su facturación electrónica.
						</li>
						<li>
							Asimismo, usaremos sus datos para contactarlo y ofrecerle nuestros servicios, promociones y
							publicidad concerniente a los mismos, así como para informarle cualquier aviso, problema o
							irregularidad que detectemos en relación a los mismos y para defender y salvaguardar
							nuestros derechos si fuese necesario.
						</li>
						<li>
							Del mismo modo, compartiremos sus datos con las autoridades fiscales, judiciales o
							administrativas que así nos lo requieran, en cumplimiento a las disposiciones legales
							aplicables.
						</li>
						<li>
							Para llevar a cabo las finalidades descritas en el presente aviso de privacidad,
							utilizaremos los siguientes datos personales: nombre completo o razón o denominación social,
							domicilio fiscal, teléfono, correo electrónico para recibir notificaciones, certificado del
							sello digital del contribuyente, así como su firma electrónica y clave del Registro Federal
							de Contribuyentes.
						</li>
						<li>
							La información que nos proporcione, no será compartida con ninguna otra empresa o compañía.
							Sin embargo, puede ser que alguna autoridad fiscal, judicial o administrativa nos solicite
							información sobre usted y que debamos entregarla en términos de la legislación aplicable. De
							ser el caso, cooperaremos con la autoridad y usted nos libera totalmente de cualquier
							responsabilidad por hacerlo.
						</li>
						<li>
							En términos de las leyes mexicanas, usted puede solicitar conocer en cualquier momento los
							datos que tenemos sobre usted, la finalidad de su tratamiento, a corregir información
							incorrecta o desactualizada, a cancelar sus datos u oponerse a su tratamiento cuando no se
							usen para las finalidades aquí señaladas. Para ejercitar estos derechos debe enviarnos un
							correo electrónico a privacidad@commersight.com,cuenta que es atendida por TECNOLOGÍA EN
							GESTIÓN DE COMERCIO ELECTRÓNICO, S.A.P.I. DE C.V. quien es responsable por el tratamiento de
							sus datos personales. Su solicitud deberá indicar con claridad quien es usted, su domicilio,
							que solicita y demás datos y documentos que estime relevantes y que nos permitan verificar
							su identidad, atender su solicitud y ponernos en contacto con usted para darle respuesta.
							Recibido lo anterior, tendremos hasta veinte días naturales para dar respuesta a su
							solicitud. En caso de que necesitemos algún documento o información adicional de su parte o
							alguna aclaración para poder atender su solicitud, usted acuerda que nos la proporcionará.
						</li>
						<li>
							Usted comprende que, para poderle prestar el servicio, necesitamos tratar sus datos, por lo
							que en caso de que decida revocar su consentimiento al tratamiento de sus datos, nos veremos
							impedidos de continuar prestándole el servicio, sin ningún tipo de responsabilidad para
							nosotros y sin derecho a devolución alguna. Asimismo, puede ser que por obligaciones legales
							nos veamos constreñidos a seguir tratando sus datos por determinado tiempo.
						</li>
						<li>
							Al igual que en la gran mayoría de los sitios de internet actuales, en nuestra página de
							internet utilizamos cookies, web beacons y otras tecnologías informáticas a través de las
							cuales es posible monitorear su comportamiento como usuario de internet, con la única
							finalidad de saber cuándo ingresa a nuestro sitio y poderle prestar nuestros servicios de la
							forma más rápida y conveniente posible, así como para ofrecerle nuestros servicios y
							publicidad concerniente a los mismos. Cualquier dato que pudiera ser recabado por tales
							medios está sujeto al presente aviso de privacidad.
						</li>
						<li>
							Podemos también recolectar y guardar información concerniente a la tarjeta o cuenta bancaria
							que utilice para pagar nuestros servicios, con la única finalidad de poder hacer el cobro
							respectivo, tramitar una devolución o facilitarle pagos futuros.
						</li>
						<li>
							En caso de que tenga cualquier duda sobre el tratamiento de sus datos o que desee limitar su
							uso o divulgación puede ponerse en contacto con nosotros en el correo electrónico antes
							mencionado. Lo exhortamos a no compartir el nombre de usuario o contraseña que utilice para
							nuestros servicios con terceros.
						</li>
						<li>
							El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones
							derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los
							servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo
							de negocio, o por otras causas. Nos comprometemos a mantenerlo informado sobre los cambios
							que pueda sufrir presenta aviso de privacidad, a través de un comunicado vía correo
							electrónico a la dirección que nos proporcione.
						</li>
						<li>
							Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado
							por alguna conducta u omisión de nuestra parte, o presume alguna violación a las
							disposiciones previstas en la ley Federal de Protección de Datos Personales en Posesión de
							los Particulares, su reglamento y demás ordenamientos aplicables, podrá interponer su
							inconformidad o denuncia ante el Instituto Federal de Acceso a la Información y Protección
							de Datos (IFAI).
						</li>
					</ol>
				</div>
			</section>
		</Layout>
	)
}

export default PrivacyDisclaimerPage
